import React, { useState, useEffect } from 'react';

export default function (props) {
  return (
    <React.Fragment>
    <ul id="shopping-list">
      {props.shoppinglistonly.map(function (res) {
        console.log(res)
        return (
          <li className="shopList">
            <input type="checkbox" className="products" name={res.product} value={res.product}></input> {res.product} {" | "} {res.amount}
          </li>
        )
      })}
    </ul>
    <style>{`
    ul#shopping-list li {
      font-size: calc(1.3rem);
    }
  `}</style>
  </React.Fragment>
  )
}