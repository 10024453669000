import React from 'react';

// import Front from "./front";
import Front from "./michele";

// import Front from "./temp"

function App() {
  return (
    <div className="App">

      <Front />

    </div>
  );
}

export default App;
