import React, { useState, useEffect } from 'react';
import Okviewer from "okconceptviewer";

import Shoppinglist from "./shoppinglist";
import Shoppinglistcloud from "./shoppinglistcloud";

let sanityID = '5pfvd0x6';
let sanityToken = 'sk38WDggW5vr1y4Q2GaOBVwKW6PSLxNlOrpsYzkUxz3d84HINQhO1q2WF6YTE15vb2sSKBElDkUFXSfP820AvE54pUCI6cY1GSCxHDl1qTmmfBxtyFe23UU7CsIYZiO70MUi3nFgcPXgZ2putXN8yUuPTXji7DqFo1QqQwMh1n2TPBD3Q9vM';
let sanityMode = 'production';

let client = require('@sanity/client')({
  projectId: sanityID,
  dataset: sanityMode,
  token: sanityToken,
  useCdn: false
})

const serializers = {
  types: {
    code: props => (
      <pre data-language={props.node.language}>
        <code>{props.node.code}</code>
      </pre>
    )
  }
}

export default function (props) {
  const [name, setName] = useState("");
  const [quant, setQuant] = useState(1);
  const [shoppinglist, setShoppinglist] = useState([]);
  const [cloudShoppinglist, setCloudShoppinglist] = useState([{}, {}]);
  const [picker, setPicker] = useState('dan');
  const [customer, setCustomer] = useState(props.customer);
  const [shoppinglistonly, setShoppinglistonly] = useState([]);
  const [flashdata, setFlashdata] = useState('App starting up...');
  const [orderid, setOrderid] = useState(0)
  const [sanityDocumentId, setSanityDocumentId] = useState('');

  const [article, setArticle] = useState({});

  const handleSubmit = (evt) => {
    evt.preventDefault();
    console.log(`Submitting Item ${name} ${quant}`);
    setName("");
    setQuant(1);
    let newObj = Object.assign({}, { "_type": 'product', "_key": Date.now().toString(), "product": name, "amount": Number(quant), picked: false, delivered: false })
    setShoppinglist([...shoppinglist, newObj]);
    console.log("updated shopping list: ");
    console.log(shoppinglist);
  }

  const refreshPage = () => {
    window.location.reload();
  }

  const handleCloudSubmit = (evt) => {
    evt.preventDefault();

    if (shoppinglist.length > 0) {
      let customerObject = Object.assign({ _type: "shopper", shopper: customer });
      let pickerObject = Object.assign({ _type: "picker", picker: picker });
      let orderArray = shoppinglist;
      let cloudObject = Object.assign({}, {
        _type: 'order',
        customer: customerObject,
        driver: pickerObject,
        shoppinglist: [...orderArray]
      }
      );

      console.log(`Submitting Item ${cloudObject} to sanity.io`);

      client.create(cloudObject).then(res => {
        console.log(`Order was created, document ID is ${res._id}`);
        setFlashdata(`Order was created, document ID is ${res._id}`);
      })
    }
    else {
      alert('Error: Not Submitted: no data in list');
    }

  }

  const doSanityLookup = () => {
    console.log("running doSanityLookup with paraemter:");
    console.log(orderid);
    let Q = '*[_type == "order"]' + '[customer.shopper == "' + customer + '"]' + '[' + orderid.toString() + ']';
    let encodedQ = encodeURIComponent(Q);

    let sanityAPI = 'https://' + sanityID + '.api.sanity.io/v1/data/query/' + sanityMode + '?query='

    let dest = sanityAPI + encodedQ;

    fetch(dest, { mode: 'cors' })
      .then(function (response) {

        if (response.ok) {
          console.log("response ok");
          setFlashdata('Everything operating normally');
          return response.json();
        } else {
          setFlashdata('Network Error: ' + response.Error);
          throw new Error(response.Error);
        }
      })
      .then(function (myReturn) {
        console.log(myReturn.result);
        if ((myReturn !== null) && ((myReturn.result !== null))) {
          setCloudShoppinglist(myReturn.result);
          setShoppinglistonly(myReturn.result.shoppinglist);
          setSanityDocumentId(myReturn.result._id);
        }
      });
  }

  const doSanityLookupPrevious = () => {
    console.log("running doSanityLookup with paraemter:");
    console.log(orderid + "+ 1");
    let newOrderId = Number(orderid) + 1;
    setOrderid(newOrderId);
    let Q = '*[_type == "order"]' + '[customer.shopper == "' + customer + '"]' + '[' + newOrderId.toString() + ']';
    let encodedQ = encodeURIComponent(Q);

    let sanityAPI = 'https://' + sanityID + '.api.sanity.io/v1/data/query/' + sanityMode + '?query='

    let dest = sanityAPI + encodedQ;

    fetch(dest, { mode: 'cors' })
      .then(function (response) {

        if (response.ok) {
          console.log("response ok");
          setFlashdata('Everything operating normally');
          return response.json();
        } else {
          setFlashdata('Network Error: ' + response.Error);
          throw new Error(response.Error);
        }
      })
      .then(function (myReturn) {
        console.log(myReturn.result);
        if ((myReturn !== null) && ((myReturn.result !== null))) {
          setCloudShoppinglist(myReturn.result);
          setShoppinglistonly(myReturn.result.shoppinglist);
          setSanityDocumentId(myReturn.result._id);
        }
      });
  }

  useEffect(() => {
    doSanityLookup();
  }, [])

  return (
    <React.Fragment>
      <div className="orderon-container_layer__2" id="orderon-container__form">
        <h5 id="flashdata">{flashdata} <button id="refresh" onClick={refreshPage}>refresh page</button></h5>

        <h2>Order Your Items</h2>
        <h6>Current Customer: {customer}</h6>
        <h6>Current Picker: {picker}</h6>
        <form onSubmit={handleSubmit}>
          <label>
            next item:
            <input className="userInput" 
              type="text"
              value={name}
              onChange={e => setName(e.target.value)}
            />
          </label>
          <label id="quantity">
            quantity:
            <input className="userInput" 
              type="text"
              value={quant}
              onChange={e => setQuant(e.target.value)}
            />
          </label>
          <input id="submitList" type="submit" value="Add to List" />
        </form>
        <br id="orderon-formSpacer" />
        <section id="listDisplay__Okviewer">
          {/* <Okviewer spreadsheetdata={shoppinglist} /> */}
          <Shoppinglist shoppinglistonly={shoppinglist} />
        </section>
        <br />
        <button id="submitToSanity" onClick={handleCloudSubmit}>Upload this order to the cloud</button>
        <br />
      </div>
      <section id="listDisplay">
        <h3>Current Cloud Order</h3>
        <Shoppinglistcloud shoppinglistonly={shoppinglistonly} sanityDocumentId={sanityDocumentId} />
        <p id="id">cloud id: {sanityDocumentId.toString()}</p>
        <p><input className="userInputNumber" id="order" value={orderid} onChange={e => setOrderid(e.target.value)}></input></p>
        <p><button id="update" onClick={doSanityLookup}>update</button></p>
        <button id="update" onClick={doSanityLookupPrevious}>⬅️ previous</button>
        <br />
      </section>
      <br />
      <style>
        {`
        a {
          color: inherit;
        }
        @media (max-width: 800px) {
          .orderon-container_layer__2 {
            margin-top: calc(10pt + 1vh);
            margin-bottom: calc(10pt + 5vh);
          }
          .orderon-container_layer__2, section#listDisplay {
            margin-left: calc(1pt + 1vh);
          }
          button {
            width: calc(30pt + 30vw);
            height: calc(25pt + 1vh);
            background: #f5f0f0;
          }
          input.userInput, input#submitList {
            width: calc(30pt + 27vw);
            height: calc(25pt + 1vh);
            font-size: calc(1.2rem);
            background: #f0f1f5;
            margin-bottom: calc(5pt + 1vh);
          }
          input.userInputNumber {
            width: calc(10pt + 8vw);
            height: calc(25pt + 1vh);
            font-size: calc(1.2rem);
            background: #f0f5f4;
          }
          label#quantity {
            display: block;
          }
        }
        @media (min-width: 801px) {
          .orderon-container_layer__2 {
            margin-top: calc(10pt + 1vh);
            margin-bottom: calc(10pt + 3vh);
          }
          .orderon-container_layer__2, section#listDisplay {
            margin-left: calc(4pt + 2vw);
          }
          button {
            width: calc(30pt + 8vw);
            height: calc(25pt + 1vh);
          }
          input.userInput, input#submitList {
            width: calc(30pt + 8vw);
            height: calc(25pt + 1vh);
            font-size: calc(1.2rem);
          }
          input.userInputNumber {
            width: calc(10pt + 4vw);
            height: calc(25pt + 1vh);
            font-size: calc(1.2rem);
          }
        }
        `}
      </style>
    </React.Fragment>
  )
}
