import React, { useState } from 'react';

let sanityID = '5pfvd0x6';
let sanityToken = 'sk38WDggW5vr1y4Q2GaOBVwKW6PSLxNlOrpsYzkUxz3d84HINQhO1q2WF6YTE15vb2sSKBElDkUFXSfP820AvE54pUCI6cY1GSCxHDl1qTmmfBxtyFe23UU7CsIYZiO70MUi3nFgcPXgZ2putXN8yUuPTXji7DqFo1QqQwMh1n2TPBD3Q9vM';
let sanityMode = 'production';

let client = require('@sanity/client')({
  projectId: sanityID,
  dataset: sanityMode,
  token: sanityToken,
  useCdn: false
})

export default function (props) {
  let [flashdata, setFlashdata] = useState('');
  const getTher = (evt) => {
    evt.preventDefault();
    console.log(evt);

    let productID = evt.currentTarget.dataset.cloud;
    let product = evt.currentTarget.dataset.product;
    let amount = evt.currentTarget.dataset.amount;
    let delivered = evt.currentTarget.dataset.delivered;
    console.log(productID);

    let arrayIndex = 0;

    client
      .patch(props.sanityDocumentId) // Document ID to patch
      .append('shoppinglist', [
        {_key: productID + '0', _type: 'product', picked: true, amount: Number(amount), product: product }
      ])
    //  .set({ shoppinglist[arrayIndex] : picked: true }) // Shallow merge
      .commit() // Perform the patch and return a promise
      .then(updatedBike => {
        console.log('Updated! New document:')
        console.log(updatedBike)
        setFlashdata("item marked picked in cloud (refresh to view updates)");
      })
      .catch(err => {
        console.error('ERROR: The update failed: ', err.message)
        setFlashdata('ERROR: The update failed: ', err.message);
      })

    const toRemoveString = 'shoppinglist[_key=="' + productID + '"]'

    const reviewsToRemove = [toRemoveString];

    client
      .patch(props.sanityDocumentId)
      .unset(reviewsToRemove)
      .commit()

  }
  return (
    <React.Fragment>
      <span id="flashdata"><i>{flashdata}</i></span>
      <ul id="shopping-list-cloud">
      {props.shoppinglistonly.map(function (res) {
        console.log(res)
        return (
          <li className="shopList">
            <input type="checkbox" className="products" name={res.picked} value={res.picked} checked={res.picked} onClick={getTher} data-cloud={res._key.toString()} data-product={res.product} data-amount={res.amount} data-delivered={res.delivered}></input> {res.product} {" | "} {res.amount}
          </li>
        )
      })}
    </ul>
    <style>{`
      ul#shopping-list-cloud li {
        font-size: calc(1.4rem);
      }
    `}</style>
    </React.Fragment>
  )
}